@use './_v.scss' as *;

* {
    font-family: 'Poppins', sans-serif;
}
html {
    width: 100%;
}

body {
    margin: 0;
}

.color-primary {
    color: $background;
}

.nav {
    width: 100%;
    height: 80px;
    background: $background;
}

.container {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: center;
    height: 100%;
}

.splash {
    width: 100%;
    height: calc(93vh);
    background: $background;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.splash-image {
    position: absolute;
    right: -350px;
    bottom: -150px;
    height: 950px;
}

.blob-image {
    position: absolute;
    left: -500px;
    bottom: -370px;
    height: 1300px;
    opacity: 0.05;
}

.blob2-image {
    position: absolute;
    left: calc(50% - 650px);
    top: -600px;
    height: 1000px;
    opacity: 0.05;
}

.nav-header {
    color: rgba(255,255,255,0.7);
    font-size: 20px;

    &.dark {
        color: $text;
    }
}

.heavy {
    color: rgba(255,255,255,1);
    font-weight: 600;
    opacity: 1 !important;
    &.dark {
        color: $text;
    }
}

.nav-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.nav-link {
    color: rgba(255,255,255,0.7);
    font-size: 16px;
    padding: 0 16px;
    &.dark {
        color: $text;
    }
}

.button-container {
    display: flex;
    flex-direction: row;
}

.button {
    display: flex;
    align-items: center;
    justify-content:center;
    height: 52px;
    padding: 0 32px;
    border-radius: 12px;
    margin:0 16px;
    font-weight: 500;
    text-transform: capitalize;
    border: none;
    font-size: 16px;
    cursor: pointer;

    &.primary {
        background: rgba(255,255,255,0.8);
        color: $background;

        a {
            color: $background !important;
            text-decoration: none;
        }
    }

    &.light {
        background: rgba(255,255,255,0.2);
        color: rgba(255,255,255,1);

        a {
            color: rgba(255,255,255,1) !important;
            text-decoration: none;
        }
    }

    &.primary.colored {
        background: $background;
        color: rgba(255,255,255,0.9);
    }

    &.light.colored {
        background: #{$background}44;
        color: $background;
    }

    &:last-child {
        margin: 0;
    }
}

.splash-title {
    font-size: 44px;
    color: white;
    opacity: 0.8;
    padding-bottom: 1rem;
    margin-bottom: 0;
    margin-top: -80px;
}

.splash-para {
    margin-top: 1rem;
    font-size: 26px;
    color: white;
    opacity: 0.6;
    width: 700px;
}

.show-more-icon {
    font-size: 24px;
    position: absolute;
    z-index: 100;
    bottom: 16px;
    left: calc(50% - 12px);
    color: $text;
}

.about-text {
    width: 100%;
    margin-top: 15rem;
    margin-bottom: 8rem;
    text-align: center;
    font-size: 36px;
    color: $text;
    line-height: 30px;
    font-weight:600;
    position: relative;

    &:after {
        width: 50px;
        height: 5px;
        background: $background;
        content: "";
        position: absolute;
        bottom: -2rem;
        left: calc(50% - 25px);
    }
}

.about-section {
    width: 100%;
    margin-top: 76px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    div {
        display: flex;
        flex-direction: column;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 15rem;
        
        p {
            text-align: center;
        }

        .about-card-title {
            font-size: 28px;
            color: $text;
            margin-top: 3rem;
            margin-bottom: 0;
            font-weight: 600;
        }

        .about-card-text {
            font-size: 18px;
            color: $text;
            opacity:0.8;
        }
    }

    div:not(:last-child) {
        margin-right: 8rem;
    }
}

.columns {
    display: flex;
    flex-direction: row;
    margin-bottom: 15rem;

    .column {
        width: 50%;
        position: relative;
    }

    .column-image {
        display: flex;
        justify-content: center;
        align-items:center;

        img {
            width: 80%;
        }
    }
}

.detail-title {
    font-size: 42px;
    color: $text;
    font-weight: 700;
    line-height: 80px;
}

.detail-text {
    font-size: 22px;
    color: $text;
    font-weight: 400;
    opacity: 0.8;
    width: 85%;
    // line-height: 80px;
}

.aboutus-container {
    position: relative;
    width: 100%;
    margin-top: 7rem;
}

.about-box {
    font-size: 36px;
    color: rgba(255,255,255,0.9);
    font-weight: 700;
    line-height: 70px;
    background: $background;
    padding: 1rem 2rem;
    display: inline-block;
    position: absolute;
    border-radius: 3px;
    min-width: 405px;

    &:before {
        top: -10px;
        left: -10px;
        width: 470px;
        height: 170px;
        z-index: -1;
        background: $text;
        content: "";
        position: absolute;
        border-radius: 3px;
    }

    &:after {
        top: 10px;
        left: 10px;
        width: 470px;
        height: 170px;
        z-index: -1;
        border: 3px solid $text;
        background: white;
        border-radius: 3px;
        content: "";
        position: absolute;
    }

    &.left {
        right: 0px;
    }
}

.aboutus-text {
    font-size: 22px;
    color: $text;
    font-weight: 400;
    opacity: 1;
    width: 85%;
    margin-top: 20rem;
    margin-bottom: 2rem;
}

.rose-line {
    position: absolute;
    z-index: -10;
    right: -300px;
    top:0px;
}

.box {
    padding: 3rem;
    background: $background;
    color: white;
    border-radius: 32px;
    margin-bottom: 10rem;
    margin-top: -5rem;
    position: relative;

    .box-title {
        font-size: 32px;
        color: white;
        margin-top: 0;
        margin-bottom: 1rem;
        font-weight: 600;
    }

    .box-text {
        font-size: 18px;
        color: white;
        margin-top: 0;
        font-weight: 500;
        opacity: 0.8;
        width: 70%;
        overflow: none;
        margin-bottom: 2rem;
    }
    
    .blob {
        position: absolute;
        right: -200px;
        height: 600px;
        top: -200px;
        opacity: 0.1;
    }

    .box-input {
        width: 70%;
        height: 52px;
        border: none;
        border-radius: 12px;
        background: rgba(255,255,255,0.3);
        padding-left: 1rem;
        color: white;
        font-size: 16px;
        margin-bottom: 1rem;

        &::placeholder {
            color: white;
            font-size: 16px;
            opacity: 0.6;
        }
    }
}

.footer-container {
    width: 100%;
    background: #{$text}22;
    padding: 5rem 0;

}

.footer-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
}

@media only screen and (max-width : 1114px) {
    .splash-image {
        display: none;
    }
    .blob {
        display: none;
    }
    .blob-image {
        // display: none;
    }
    .blob2-image {
        // display: none;
    }

    .container {
        width: 100%;
        margin: 0 16px;
    }

    .splash-title {
        font-size: 1.8rem;
    }
    .splash-para {
        font-size: 1.2rem;
        width: calc(100% - 32px) !important;

    }
    .container {
        flex-wrap: wrap;

        .nav-links {
            display: none;
        }
    }

    .footer-container {
        flex-wrap: wrap;

        .nav-header {
            width: calc(100% - 32px);
            text-align: center;
        }

        .nav-links {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 32px);
            align-items: center;
            justify-content:center;
        }

        .nav-link {
            width: 20%;
        }
    }

    .footer-nav {
        flex-wrap: wrap;
    }

    .columns {
        flex-wrap: wrap;

        .column{ width: 100%;}
        .column-image { display: none;}
    }

    .about-section {
        flex-wrap: wrap;
        justify-content:center;
        align-items: center;

        div {
            margin-right: 0px !important;
            padding-bottom: 5rem;
        }

        div:last-child {
            padding-bottom: 10rem;
        }
    }
    .about-box {
        font-size: 24px;
        line-height: 50px;
        min-width: 320px;
    
        &:before {
            width: 340px;
            height: 130px;
        }
    
        &:after {
            width: 340px;
            height: 130px;
        }
    
        &.left {
            right: 0px;
        }
    }

    .box {
        width: calc(100% - 130px) !important;

        .box-input {
            width: 100%;
        }

        .box-text {
            width: 100%;
        }
    }

    .rose-line {
        display: none;
    }
}

.auth-background {
    background: rgb(236,81,105);
background: radial-gradient(circle, rgba(236,81,105,1) 0%, rgba(237,60,88,1) 100%);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-panel {
    width: 500px;
    padding: 2rem;
    background: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 5px 5px 10px 5px rgba(0,0,0,0.2);

    
    h1 {
        text-align: center;
        margin-bottom: 0;

        span {
            color: $background;
        }
    }

    p {
        text-align: center;
        margin-bottom: 2rem;
        opacity: 0.8;
    }

    input {
        height: 52px;
        font-size: 16px;
        background: #4a4a4a11;
        border: none;
        border-radius: 12px;
        padding-left: 1rem;
        margin-bottom: 1rem;
    }

    .auth-change {
        margin-top: 2rem;
        margin-bottom: 0;
        font-size: 14px;
        color: $background;
        text-decoration: underline;
    }

    .auth-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .error {
        margin: 0;
        color: $background;
        font-size: 14px;
    }
}